<template>
  <div class="root flex_center">
    <div class="swiper_con">
      <div class="title_box flex_between">
        <Title :titleName="titleName"></Title>
      </div>
      <!-- <div class="tooltip">
        <div class="title_box flex_align" style=" display: flex;align-items: center;">
          <img class="logo" src="https://mentor.beliwin.com/apistatic/poster/img.png" alt="" />
          <div class="text">
            <p class="school">哥伦比亚大学</p>
            <p class="subtitle">海师专业人数分布</p>
          </div>
        </div>
        <div class="famouseTooltipChart" id="famouseTooltipChart"></div>
      </div> -->
      <div id="famous_chart" v-if="chartShow"></div>
      <Arrow></Arrow>
    </div>
  </div>
</template>
<script>
import Title from "../title";
import Arrow from "../arrow";
import * as echarts from "echarts";
import { getFamous } from "@/api/home.js";
import commonMeth from "../../commonMeth/index";
export default {
  name: "",
  components: {
    Title,
    Arrow,
  },
  props: ["swiperName"],
  data() {
    return {
      chartShow: true,
      titleName: {
        chinese: "海师名校分布 TOP12",
        english: "海外名師の出身大学 TOP12",
      },
      allData: [],
      xAxisData: [],
      seriesData: [],
      // tooltip中图表配置
      twoOption: {
        title: {
          left: "center",
        },
        tooltip: {
          trigger: "item",
        },

        legend: {
          icon: "rect",
          itemHeight: 12,
          itemWidth: 12,
          orient: "vertical",
          // x:'right',      //可设定图例在左、右、居中
          x: "79%",
          y: "center",
          itemGap: 20,
          textStyle: {
            color: "#62472c",
          },
        },
        series: [
          {
            type: "pie",
            radius: "70%",
            center: ["35%", "53%"],
            data: [],
            itemStyle: {
              normal: {
                borderWidth: 2,
                borderColor: "#fff",
                color: function (params) {
                  //自定义颜色
                  var colorList = [
                    "#CAAD5F",
                    "#D3B564",
                    "#DCBC68",
                    "#E4C46D",
                    "#EDCC71",
                    "#F6D375",
                    "#F6D375",
                    "#F6D375",
                    "#F6D375",
                    "#F6D375",
                    
                  ];
                  return colorList[params.dataIndex];
                },
                labelLine: {
                  lineStyle: {
                    color: "#ededed",
                  },
                },
              },
            },
          },
          {
            type: "pie",
            radius: "70%",
            center: ["35%", "53%"],
            data: [
              // { value: 1048, name: "学术课程指导" }
            ],
            itemStyle: {
              normal: {
                borderWidth: 2,
                borderColor: "#fff",
                label: {
                  show: true,
                  position: "inner",
                  formatter: "{c}人",
                  color: "#fff",
                },
                color: function (params) {
                  //自定义颜色
                  var colorList = [
                    // "#CAAD5F",
                    // "#D3B564",
                    // "#DCBC68",
                    // "#E4C46D",
                    // "#EDCC71",
                    // "#F6D375",
                    // "#F6D375",
                  ];
                  return colorList[params.dataIndex];
                },
              },
            },
          },
        ],
      },
      curSchoolName: "",
    };
  },
  watch: {
    // 监听父组件swiper变化，重新渲染图表
    swiperName(val) {
      val === "famousSchool" ? this.renderChart(true) : this.renderChart(false);
    },
  },
  created() {},
  beforeMount() {},
  mounted() {
    // tooltip图表点击事件
    window.toPathFamous = (val,school) => {
      this.openNewTab({name:val,query:{school:school}})
    };
    // 获取数据
    getFamous().then((res) => {
      if (res.code === 200) {
        let resData = res.data;
        this.allData = res.data;
        resData.forEach((item) => {
          this.xAxisData.push(item.ename_varchar);
        });
        resData.forEach((item) => {
          this.seriesData.push(item.list.count);
        });
        this.initChart();
      }
    });
  },
  methods: {
    // 初始化图表
    initChart() {
      let that = this;
      var myChart = echarts.init(document.getElementById("famous_chart"));
      var option = {
        color: ["#c5a95d"],  //柱状颜色
        grid:{
          left:'0%',
          right:'0%'
        },
        // barBorderColor: "red",
        tooltip: {
          borderRadius: "10",
          trigger: "axis",
          triggerOn: "click",
          axisPointer: {
            type: "shadow",
            // 鼠标移入阴影颜色
            shadowStyle: {
              width: "auto",
              color: "rgba(202, 173, 95,0.15)",
            },
          },
          enterable: true,
          borderColor: "#fff",
          textStyle: {
            
            // color: "red",
          },
          formatter: function (params, ticket, callback) {
            console.log("params", params[0].axisValue);

            let filterData = that.allData.filter((item) => {
              if (item.ename_varchar === params[0].axisValue) return item;
            });
            let schoolName = filterData[0].ename_varchar;
            console.log('学校名称',schoolName);

            let schoolImg = filterData[0].schoolbadge_img;
            that.twoOption.series[0].data = that.transformKey(
              filterData[0].list.serviceList
            );
            that.twoOption.series[1].data = that.transformKey(
              filterData[0].list.serviceList
            );
            var html = `   <div class="tooltip" style="padding:40px;border-radius:16px;">
              <div class="title_box flex_align" style=" display: flex;align-items: center;">
                <img class="logo" src="${schoolImg}" style="width: auto;height: 54px;" alt="" />
                <div class="text" style=" height: 100%;display: flex;flex-direction: column;justify-content: space-between;margin-left: 10px;">
                  <p class="school">${schoolName}</p>
                  <p class="subtitle">海师专业人数分布</p>
                </div>
              </div>
              <div class="famouseTooltipChart" onclick="toPathFamous('haishi','${params[0].axisValue}')" id="famouseTooltipChart" style="width:700px;height:330px;"></div>
            </div>`;
            callback(that.setTooltipEchart());
            return html;
          },
        },

        xAxis: {
          type: "category",
          data: that.xAxisData,
          axisLabel: {
            interval: 0, //无效
            rotate: -45, //无效
            textStyle: {
              color: "#666", //有效
              fontFamily: 'japanFont',
            },
          },
        },

        yAxis: {
          type: "value",
          splitLine: {
            show: true,
            lineStyle: {
              color: ["#e3e3e3"],
              width: 1,
              type: "dashed",
            },
          },
        },
        series: [
          {
            barWidth: 40, //柱图宽度
            barGap: "10%", //柱图间距
            data: that.seriesData,
            type: "bar",
            itemStyle: {
              normal: {
                label: {
                  show: true, //开启显示
                  position: "top", //在上方显示
                  textStyle: {
                    //数值样式
                    color: "#666666",
                    fontSize: 16,
                  },
                },
              },
              emphasis: {
                // color: "#f3bc70",
                barBorderColor: "#fff",
                barBorderWidth: "2",
                label: {
                  textStyle: {
                    //数值样式
                    color: "#caad5f",
                    fontSize: 16,
                    fontWeight: 600,
                  },
                },
              },
            },
          },
        ],
      };
      myChart.setOption(option);
      // myChart.on("click",  (params)=> {
      //   console.log(this);
      //   console.log(params);
      //   this.curSchoolName=params.name;
      // });
    },
    //tooltip中饼图渲染
    setTooltipEchart() {
      const that = this;
      that.$nextTick(() => {
        that.tooltipEchart = echarts.init(
          document.getElementById("famouseTooltipChart")
        );
        that.tooltipEchart.setOption(that.twoOption);
      });
    },
    // 转换obj中key值
    transformKey(array) {
      var keyMap = {
        count: "value",
        title: "name",
      };
      for (var i = 0; i < array.length; i++) {
        var obj = array[i];
        for (var key in obj) {
          var newKey = keyMap[key];
          if (newKey) {
            obj[newKey] = obj[key];
            delete obj[key];
          }
        }
      }
      return array;
    },
    // 重新渲染图表
    renderChart(val) {
      this.chartShow = val;
      if (val) {
        this.$nextTick(() => {
          this.initChart();
        });
      }
    },
    openNewTab(to) {
      const routeData = this.$router.resolve(to);
      window.open(routeData.href, "_blank");
    },
  },
  computed: {},
};
</script>

<style lang='scss' scoped>
div,
p {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
ul,
li {
  list-style: none;
}
.root {
  background: url(./image/bg.png) no-repeat;
  background-size: (100% 100%);
  height: 100%;
}
.title_box .right {
  letter-spacing: 1px;
}
.flex_box {
  display: flex;
}
.flex_align {
  display: flex;
  align-items: center;
}

.flex_between {
  display: flex;
  justify-content: space-between;
}
.flex_center {
  display: flex;
  justify-content: center;
  align-items: center;
}

// 图表展示
#famous_chart {
  width: 1200px;
  height: 700px;
  margin: 0 auto;
}
.swiper_con {
  width: 1200px;
  height: 800px;
  display: flex;
  flex-direction: column;
  position: relative;
}

// tooltip
.famouseTooltipChart {
  width: 704px;
  height: 350px;
}
.tooltip {
  width: 764px;
  padding: 30px;
  border-radius: 26px;
  box-shadow: 20px 20px 60px #d9d9d9, -20px -20px 60px #ffffff;
}
.tooltip .text {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 10px;
}

.logo {
  width: auto;
  height: 54px;
}
.school {
  font-size: 20px;
  font-weight: 600;
  color: #666666;
}
.subtitle {
  font-size: 16px;
  font-weight: 400;
  color: #666666;
  line-height: 24px;
}
</style>
