<template>
  <div class="riben">
    <swiper ref="mySwiper" :options="swiperOptions" class="stop-swiping">
      <swiper-slide><Video></Video></swiper-slide>
      <swiper-slide><Service></Service></swiper-slide>
      <swiper-slide><Introduce></Introduce></swiper-slide>
      <swiper-slide
        ><CaseData :swiperName="swiperName" ref="caseData"></CaseData
      ></swiper-slide>
      <swiper-slide
        ><Identity :swiperName="swiperName"></Identity
      ></swiper-slide>
      <swiper-slide
        ><FamousSchool :swiperName="swiperName"></FamousSchool
      ></swiper-slide>
      <!-- <swiper-slide><Major :swiperName="swiperName"></Major></swiper-slide> -->
      <swiper-slide><Professor :swiperName="swiperName"></Professor></swiper-slide>
      <swiper-slide>
        <swiper :options="swiperOptionsTwo">
          <swiper-slide>
            <div class="long_con">
              <Comment></Comment>
            </div>
          </swiper-slide>
          <div class="swiper-scrollbar"></div>
        </swiper>
      </swiper-slide>
      <swiper-slide style="height:462px!important;">
        <Footer></Footer>
      </swiper-slide>
    </swiper>
    <div class="swiper-pagination"></div>
  </div>
</template>
<script>
// import Swiper from "swiper";
import Header from "@/components/header/index.vue";
import Video from "./components/video";
import Service from "./components/service";
import Introduce from "./components/introduce/index";
import CaseData from "./components/caseData/index";
import Identity from "./components/identity/index";
import FamousSchool from "./components/famousSchool/index";
import Major from "./components/major/index";
import Professor from "./components/professor/index";
import Comment from "./components/comment/index";
import { Bus } from "@/views/home/components/bus.js";
import * as echarts from "echarts";
import Footer from "@/components/footer.vue";

let vm = null;
export default {
  name: "",
  props: [""],
  data() {
    return {
      swiperName: "",
      swiperOptions: {
        direction: "vertical", //方向
        loop: false, //循环
        mousewheel: true,
        slidesPerView: "auto",
        noSwiping: true,
        noSwipingClass: "stop-swiping",
        pagination: {
          el: ".swiper-pagination",//分页器
          clickable:true,//开启切换
          renderBullet: function (index, className) {
            let text
            switch(index){
              case 0:text='首页';break;
              case 1:text='海师服务';break;
              case 2:text='海师资质';break;
              case 3:text='成功案例';break;
              case 4:text='画像分析';break;
              case 5:text='名校分布';break;
              // case 6:text='专业概览';break;
              case 6:text='教授概览';break;
              case 7:text='互动评价';break;
              case 8:text='联系我们';break;
            }
            return `<span class="${className}"><i></i><div>${text}</div></span>`;
          },
        },
        navigation: {
          nextEl: " .swiper_next",
          prevEl: " .swiper_pre",
        },
        keyboard: true,
        speed: 600,
        touchStartPreventDefault: false,
        on: {
          // 判断是否显示header
          transStart: function() {
            vm.swiperShow = false;
          },
          click: function() {},
          slideChange: function(swiper, event) {
            if (this.activeIndex !== 0) {
              Bus.$emit("changeHeaderShow", false)
            }else {
              Bus.$emit("changeHeaderShow", true)
            }
            switch (this.activeIndex) {
              case 2: //4
                vm.swiperName = "test";
                break;
              case 3: //成功案例
                vm.swiperName = "caseData";
                break;
              case 4: //画像
                vm.swiperName = "identity";
                break;
              case 5:
                vm.swiperName = "famousSchool";
                break;
              // case 6:
              //   vm.swiperName = "major";
              //   break;
                case 6:
                vm.swiperName = "Professor";
                break;
              case 7:
                vm.swiperName = "comment";
                break;
            }
            vm.swiperIndex = this.activeIndex;
          },
        },
      },
      swiperOptionsTwo: {
        direction: "vertical",
        nested: true,
        //resistanceRatio: 0,
        slidesPerView: "auto",
        freeMode: true,
        scrollbar: {
          el: ".swiper-scrollbar",
        },
      },
      swiperShow: true,
    };
  },
  components: {
    Video,
    Service,
    Introduce,
    CaseData,
    Identity,
    FamousSchool,
    // Major,
    Professor,
    Comment,
    Footer,
  },
  watch: {},
  created() {
    vm = this;
    // 点击服务页面跳转到成功案例swiper
    Bus.$on("toCaseData", this.toCaseData);
  },

  beforeMount() {},
  mounted() {},
  methods: {
    toCaseData(menuText) {
      this.swiper.slideTo(3, 1000, false);
    },
  },
  computed: {
    //当前swiper
    swiper() {
      return this.$refs.mySwiper.swiper;
    },
  },
};
</script>
<style>
/* .riben{
  font-family:"游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", 'serif'
} */
</style>
<style scoped>
#nav {
  padding: 0 !important;
}
</style>
<style lang="scss" scoped>
html,
body {
  position: relative;
  height: 100%;
}

body {
  background: white;
  font-size: 14px;
  color: #000;
  margin: 0;
  padding: 0;
}

.flex_align {
  display: flex;
  align-items: center;
}
.flex_between {
  display: flex;
  justify-content: space-between;
}
div,
p {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
//小圆点分页器
/deep/.swiper-pagination {
  position: fixed;
  right: 5%;
  top: 50%;
  transform: translateY(-50%);
  // pointer-events: none;
  z-index: 9999999;
  display: flex;
  flex-direction: column;
  .swiper-pagination-bullet{
    margin: 10px 0px;
    width: 20px;
    height: 20px;
    opacity: 1;
    font-size: 18px;
    text-align: center;
    line-height: 12px;
    box-sizing: border-box;
    background: none;
    position: relative;
    i{
      width: 6px;
      height: 6px;
      background: #CCCCCC;
      display: inline-block;
      border-radius: 50%;
    }
    // 去掉点击默认样式
    &:focus
     {
      outline: none;
    }
    &:hover{
       border: 1px solid #999;
      -webkit-border-radius: 50%;
      border-radius: 50%;
      box-sizing: border-box;
      >div{
        display: block;
      }
    }
    >div{
      position: absolute;
      bottom: 4px;
      font-size: 12px;
      font-weight: 400;
      color: #CCCCCC;
      left: -60px;
      display: none;
      white-space: nowrap;
    }
  }
  .swiper-pagination-bullet-active{
    border: 1px solid #999;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    box-sizing: border-box;
    >div{
        display: block;
      }
  }
}
.swiper-container {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  font-size: 18px;
  background: #fff;
}
.swiper-container {
  width: 100vw;
  height: 100vh;
}
.swiper-slide {
  // height:100vh!important;
  width: 100vw;
  height: 100vh;
}
.swiper_one {
  // background: url(./image/swiperOneBg.png) no-repeat;
  background-size: 100%;
}
// .longSwiper {
//   font-size: 18px;
//   height: auto;
//   -webkit-box-sizing: border-box;
//   box-sizing: border-box;
//   display: block;
//   // line-height: 100px;
//   // background: #4390ee;
// }
.long_con {
  width: 100vw;
  height: 100vh;
}
</style>
