<template>
  <div class="title">
      <p class="chinese">{{titleName.chinese}}</p>
      <p class="english">{{titleName.english}}</p>
  </div>
</template>
<script>
  export default {
    name:'',
    components: {},
    props:{
      titleName:{

      }
    },
    data () {
      return {

      };
    },
    watch: {},
    created() {},
    beforeMount() {},
    mounted() {},
    methods: {},
    computed: {}
  }
</script>
<style lang='scss' scoped>

.xian{
  color: #803444;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  margin-top: 10px;
  img{
    width: 80px;
  }
}
.chinese {
  font-size: 36px;
  font-weight: 400;
  color:#3A3A3A;
  letter-spacing: 2px;
}
.english {
  font-size: 20px;
  font-weight: 400;
  color: #4D4D4D;
  margin-top: 10px;
}
</style>
