<template>
  <div>
    <!-- 视频播放弹窗 -->
    <el-dialog
      title=" 1"
      :visible.sync="dialogVisible"
      width="40%"
      :modal="true"
      :close-on-click-modal="false"
      :append-to-body="true"
    >
      <div class="video_box">
        <video-player
          class="video-player vjs-custom-skin"
          ref="videoPlayer"
          :playsinline="true"
          :options="playerOptions"
          @ready="playerReadied"
          @timeupdate="onPlayerTimeupdate($event)">
        </video-player>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: "",
  components: {},
  props: ["type"],
  data() {
    return {
      //视频播放配置
      playerOptions: {
        playbackRates: [0.5, 1.0, 1.5, 2.0], // 可选的播放速度
        autoplay: true, // 如果为true,浏览器准备好时开始回放。
        muted: false, // 默认情况下将会消除任何音频。
        loop: false, // 是否视频一结束就重新开始。
        preload: "auto", // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: "zh-CN",
        aspectRatio: "4:3", // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: false, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [
          {
            type: "video/mp4", // 类型
            src: "",
          },
        ],
        poster: "", // 封面地址
        notSupportedMessage: "此视频暂无法播放，请稍后再试", // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
          timeDivider: true, // 当前时间和持续时间的分隔符
          durationDisplay: true, // 显示持续时间
          remainingTimeDisplay: true, // 是否显示剩余时间功能
          fullscreenToggle: true, // 是否显示全屏按钮
        },
        // controlBar: false,
      },
      gklog: "", //视频观看时长
      dialogVisible: false,
    };
  },
  watch: {
    dialogVisible(val) {
      if (!val) this.cancel();
      console.log('监听',val);

    },
  },
  created() {},
  beforeMount() {},
  mounted() {},
  methods: {
    show(url) {
      this.playerOptions.sources[0].src = url;
      this.dialogVisible = true;
      this.$nextTick(() => {
        this.$refs.videoPlayer.player.src(url); // 重置进度条
        this.$refs.videoPlayer.player.play();
      });
    },
    /* 获取视频播放进度 */
    onPlayerTimeupdate(player) {
      this.gklog = player.cache_.currentTime;
      if (this.type === "comment") {
        if (this.gklog > 3) {
          this.$refs.videoPlayer.player.pause(); // 暂停
        }
      }
    },
    /* 设置视频进度 */
    playerReadied(player) {
      player.currentTime(this.gklog);
    },
    // 取消按钮
    cancel() {
      this.dialogVisible = false;
      // let src =
      //   "https://f.video.weibocdn.com/LnIbgmSxlx07LSKChQs8010412002aYt0E010.mp4?label=mp4_ld&template=360x620.24.0&trans_finger=81b11b8c5ffb62d33ceb3244bdd17e7b&ori=0&ps=1BVp4ysnknHVZu&Expires=1618555621&ssig=9zzy2qlhHm&KID=unistore,video";
      // this.playerOptions.src = src;
      this.$refs.videoPlayer.player.pause();
      console.log('取消');


      if (this.type === "bgVideo") {
        this.$emit("pauseBgVideo", false);
      }
    },
  },
  computed: {},
};
</script>
<style lang='scss' scoped>
// dialog中按钮样式
.dialog_btn,
.dialog_btn:hover {
  background: linear-gradient(270deg, #f3bc70 0%, #f8e2c4 100%);
  border: none;
  color: #935d14;
}
// dialog样式
/deep/ .el-dialog__header {
  // padding:0;
  color: transparent;
}
/deep/ .el-dialog__body {
  padding: 0;
}
/deep/ .el-dialog__title {
  color: transparent;
}
/deep/ .el-dialog {
  background: transparent;
}
/deep/ .el-dialog__close {
  color: #fff;
}
/deep/ .el-dialog {
  box-shadow: none;
}
/deep/ [data-v-b9dade24] .el-dialog__close {
  margin-right: -50px;
}
/deep/ .el-dialog__headerbtn {
  right: -20px;
}
</style>
