import router from "../../../../router/index";
var toPath = (val, queryName = "", queryVal = "") => {
  console.log("val", queryVal);
  if (queryName) router.push(`/${val}?${queryName}=${queryVal}`);
  else router.push(`/${val}`);
};
var openNewTab = (to) => {
  console.log('新tab',to);
  const routeData = router.resolve(to);
  window.open(routeData.href, "_blank");
};
export default {
  toPath,openNewTab
};
