<template>
  <div class="root flex_center">
    <div class="swiper_con">
      <div class="title_box flex_between">
        <Title :titleName="titleName"></Title>
        <div
          class="right flex_align animated fadeIn"
          @click="openNewTab({ name: 'teachlist' }, true)"
        >
          查询 <i class="el-icon-caret-right listIcon"></i>
        </div>
      </div>
      <div id="professor_chart" ref="professor_chart" v-if="chartShow"></div>
      <Arrow></Arrow>
    </div>
  </div>
</template>
<script>
import Title from "../title";
import Arrow from "../arrow";
import * as echarts from "echarts";
import { getFamous, getTeach } from "@/api/home.js";
import commonMeth from "../../commonMeth/index";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
am4core.useTheme(am4themes_animated);
let vm = null;

export default {
  name: "",
  components: {
    Title,
    Arrow,
  },
  props: ["swiperName"],
  data() {
    return {
      chartShow: true,
      titleName: {
        chinese: "日本教授概览",
        english: "日本の教授の概要",
      },
      chartData: [],
      allData: [],
      xAxisData: [
        // "东京大学",
        // "京都大学",
        // "东京工业大学",
        // "大阪大学",
        // "东北大学",
        // "名古屋大学",
        // "九州大学",
      ],
      seriesData: [],
      // tooltip中图表配置
      twoOption: {
        title: {
          left: "center",
        },
        tooltip: {
          trigger: "item",
        },

        legend: {
          icon: "rect",
          itemHeight: 12,
          itemWidth: 12,
          orient: "vertical",
          // x:'right',      //可设定图例在左、右、居中
          x: "79%",
          y: "center",
          itemGap: 20,
          textStyle: {
            color: "#62472c",
          },
        },
        series: [
          {
            type: "pie",
            radius: "70%",
            center: ["35%", "53%"],
            data: [],
            itemStyle: {
              normal: {
                borderWidth: 2,
                borderColor: "#fff",
                color: function(params) {
                  //自定义颜色
                  var colorList = [
                    "#f3bc70",
                    "#f4c079",
                    "#f4c380",
                    "#f4c788",
                    "#f5c98e",
                    "#f5cb92",
                    "#f5cd95",
                    "#f6d09c",
                    "#f6d3a3",
                    "#f6d6aa",
                    "#f7dab2",
                    "#f7ddb8",
                  ];
                  return colorList[params.dataIndex];
                },
                labelLine: {
                  lineStyle: {
                    color: "#ededed",
                  },
                },
              },
            },
          },
          {
            type: "pie",
            radius: "70%",
            center: ["35%", "53%"],
            data: [
              // { value: 1048, name: "学术课程指导" }
            ],
            itemStyle: {
              normal: {
                borderWidth: 2,
                borderColor: "#fff",
                label: {
                  show: true,
                  position: "inner",
                  formatter: "{c}人",
                  color: "#fff",
                },
                color: function(params) {
                  //自定义颜色
                  var colorList = [
                    "#f3bc70",
                    "#f4c079",
                    "#f4c380",
                    "#f4c788",
                    "#f5cc93",
                    "#f6d09c",
                    "#f6d5a7",
                    "#f6d5a7",
                    "#f6d5a7",
                    "#f6d5a7",
                    "#f6d5a7",
                  ];
                  return colorList[params.dataIndex];
                },
              },
            },
          },
        ],
      },
      curSchoolName: "",
    };
  },
  watch: {
    // 监听父组件swiper变化，重新渲染图表
    swiperName(val) {
      val === "Professor" ? this.renderChart(true) : this.renderChart(false);
    },
  },
  created() {
    vm = this;
  },
  beforeMount() {},
  mounted() {
    // this.initChart();
    // tooltip图表点击事件
    // 获取数据
    getTeach().then((res) => {
      if (res.code === 200) {
        let resData = res.data;
        this.chartData = res.data;
        this.allData = res.data;
        resData.forEach((item) => {
          this.xAxisData.push(item.xname);
        });
        resData.forEach((item) => {
          this.seriesData.push(item.number);
        });
        this.initChart();
      }
    });
  },
  methods: {
    //初始化图表
    initChart(data) {
      let chart = am4core.create(this.$refs.professor_chart, am4charts.XYChart);
      chart.background.opacity = 0
      let keys = [];
      let colors = ["#E1A679", "#DAC9A6", "#CAAD5F", "#B5CAA0", "#58B2DC"];
      this.chartData.forEach((item, key) => {
        if (key % 3 == 0) {
          keys.push(key);
        }
      });
      keys.forEach((item, key) => {
        this.chartData[item].lineColor = am4core.color(colors[key]);
      });
      chart.data = this.chartData;
      console.log(this.chartData);
      let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
      categoryAxis.renderer.grid.template.location = 0;
      categoryAxis.renderer.ticks.template.disabled = true;
      categoryAxis.renderer.line.opacity = 0;
      categoryAxis.renderer.grid.template.disabled = true;
      categoryAxis.renderer.minGridDistance = 40;
      categoryAxis.dataFields.category = "xname";
      categoryAxis.startLocation = 0.4;
      categoryAxis.endLocation = 0.6;

      let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
      valueAxis.tooltip.disabled = true;
      valueAxis.renderer.line.opacity = 0;
      valueAxis.renderer.ticks.template.disabled = true;
      valueAxis.min = 0;
      valueAxis.renderer.grid.template.strokeOpacity = 0.1;
      valueAxis.renderer.grid.template.strokeWidth = 0.5;
      valueAxis.renderer.grid.template.strokeDasharray = "6,6";

      // valueAxis.renderer.grid.template.disabled = true;
      // valueAxis.renderer.grid.template.disabled = true;
      let lineSeries = chart.series.push(new am4charts.LineSeries());
      lineSeries.dataFields.categoryX = "xname";
      lineSeries.dataFields.valueY = "number";
      lineSeries.tooltipText = "{xname}: {valueY.value}";
      lineSeries.fillOpacity = 0.5;
      lineSeries.strokeWidth = 3;
      lineSeries.propertyFields.stroke = "lineColor";
      lineSeries.propertyFields.fill = "lineColor";
      let bullet = lineSeries.bullets.push(new am4charts.CircleBullet());
      bullet.circle.radius = 6;
      bullet.circle.stroke = am4core.color("#D99D66");
      bullet.events.on("hit", function(event, a) {
        let val = "haishi";
        console.log(event)
        vm.openNewTab({
          name: 'teachlist',
          query: { school: event.target.dataItem.categories.categoryX },
        });
      });
      bullet.circle.fill = am4core.color("#fff");
      bullet.circle.strokeWidth = 3;
      chart.cursor = new am4charts.XYCursor();
      chart.cursor.behavior = "panX";
      chart.cursor.lineX.opacity = 0;
      chart.cursor.lineY.opacity = 0;
    },

    //tooltip中饼图渲染
    setTooltipEchart() {
      const that = this;
      that.$nextTick(() => {
        that.tooltipEchart = echarts.init(
          document.getElementById("famouseTooltipChart")
        );
        that.tooltipEchart.setOption(that.twoOption);
      });
    },
    // 转换obj中key值
    transformKey(array) {
      var keyMap = {
        count: "value",
        title: "name",
      };
      for (var i = 0; i < array.length; i++) {
        var obj = array[i];
        for (var key in obj) {
          var newKey = keyMap[key];
          if (newKey) {
            obj[newKey] = obj[key];
            delete obj[key];
          }
        }
      }
      return array;
    },
    // 重新渲染图表
    renderChart(val) {
      this.chartShow = val;
      if (val) {
        this.$nextTick(() => {
          this.initChart();
        });
      }
    },
    openNewTab(to, noQuery) {
      const routeData = this.$router.resolve(to);
      if (noQuery) {
        window.open(`#/${to.name}`, "_blank");
        return;
      }
      window.open(`#/${to.name}?school=${to.query.school}`, "_blank");
    },
    // 跳转页面
    toPath(val) {
      // 公共方法
      commonMeth.openNewTab({ name: val });
    },
  },
  computed: {},
};
</script>

<style lang="scss" scoped>

div,
p {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
ul,
li {
  list-style: none;
}
.root {
  background: url(./image/bg.png) no-repeat;
  background-size: (100% 100%);
  height: 100%;
}
.title_box .right {
  letter-spacing: 1px;
  cursor: pointer;
}
.flex_box {
  display: flex;
}
.flex_align {
  display: flex;
  align-items: center;
}

.flex_between {
  display: flex;
  justify-content: space-between;
}
.flex_center {
  display: flex;
  justify-content: center;
  align-items: center;
}

// 图表展示
#professor_chart {
  width: 1200px;
  height: 700px;
  margin: 0 auto;
  // background: rgba($color: #000000, $alpha: 0.1);
}
.swiper_con {
  width: 1200px;
  height: 850px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
}

// tooltip
.famouseTooltipChart {
  width: 704px;
  height: 350px;
}
.tooltip {
  width: 764px;
  padding: 30px;
  border-radius: 26px;
  box-shadow: 20px 20px 60px #d9d9d9, -20px -20px 60px #ffffff;
}
.tooltip .text {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 10px;
}

.logo {
  width: auto;
  height: 54px;
}
.school {
  font-size: 20px;
  font-weight: 600;
  color: #666666;
}
.subtitle {
  font-size: 16px;
  font-weight: 400;
  color: #666666;
  line-height: 24px;
}
</style>
