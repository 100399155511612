<template>
  <div class="out">
    <i class="arrow iconfont xhgpage-arrow"></i>
  </div>
</template>
<script>
export default {
  name: "",
  components: {},
  props: [""],
  data() {
    return {};
  },
  watch: {},
  created() {},
  beforeMount() {},
  mounted() {},
  methods: {},
  computed: {},
};
</script>
<style lang='scss' scoped>
.out {
  position: absolute;
  bottom: 20px;
  left: calc(50% - 18px);
}
.arrow {
  position: absolute;
  bottom: 0;
  left: calc(50% - 18px);
  color: #666;
  /*调用动画 */
  /* animation：动画名称 花费时间 运动曲线 何时开始 播放次数 是否反方向 */
  animation: move 0.8s ease 0s infinite alternate;
  -webkit-animation: move 0.8s ease 0s infinite alternate;
}

/* 声明动画 关键帧 @keyframes 动画名称{} */
@keyframes move {
  from {
    top: 0;
    opacity: 0.3;
    // background-color: pink;
  }
  to {
    top: 30px;
    opacity: 1;
    // background-color: yellow;
  }
}
@-webkit-keyframes move {
  from {
    top: 0;
    opacity: 0.3;
    // background-color: yellow;
  }
  to {
    top: 30px;
    opacity: 1;
    // background-color: yellow;
  }
}
@-ms-keyframes move {
  from {
    top: 0;
    opacity: 0.3;
    // background-color: pink;
  }
  to {
    top: 30px;
    opacity: 1;
    // background-color: yellow;
  }
}
</style>
