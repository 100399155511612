<template>
  <div class="root flex_center flex_col">
    <div class="swiper_con relative commentSwiper">
      <div class="title_box flex_between_align_center">
        <Title :titleName="titleName"></Title>
      </div>
      <!-- 评价列表 -->
      <div class="con_box flex_center relative">
        <swiper ref="swiper" :options="swiperOptions">
          <swiper-slide :key="index" v-for="(item, index) in commentList">
            <CommentItem :comment="item" @playVideo="playVideo()"></CommentItem>
          </swiper-slide>
        </swiper>
        <div class="swiper-button-prev" slot="button-prev"></div>
        <div class="swiper-button-next" slot="button-next"></div>
      </div>
    </div>
    <VideoDialog ref="videoDialog" type="service"></VideoDialog>
  </div>
</template>
<script>
import Title from "../title";
import CommentItem from "../comment/components/commentItem.vue";
import * as echarts from "echarts";
import { getComment } from "@/api/home.js";
import Footer from "@/components/footer.vue";
import VideoDialog from "@/views/home/meiyan/components/videoDialog";
export default {
  name: "",
  components: {
    Title,
    CommentItem,
    VideoDialog
  },
  props: [""],
  data() {
    return {
      titleName: {
        chinese: "海师学生互动评价",
        english: "海外名師と生徒の間の相互評価",
      },
      swiperOptions: {
        slidesPerView: 2,
        slidesPerGroup: 2,
        spaceBetween: 30,
        navigation: {
          nextEl: ".commentSwiper .swiper-button-next",
          prevEl: ".commentSwiper .swiper-button-prev",
        },
        loop: false,
        mousewheel: false,
      },
      // 评价列表
      commentList: [
        {
          hwds: { id: 1, name: "张同学", hstp: "", major: "", school: [] },
          student: { star: 4, content: "11", type: 1 },
          teacher: { star: 5, content: "11", type: 1 },
        },
      ],
    };
  },
  watch: {},
  created() {},
  beforeMount() {},
  mounted() {
    // 获取数据
    getComment().then((res) => {
      if (res.code === 200) {
        this.commentList = res.data;
      }
    });
  },
  methods: {
    playVideo(url) {
      this.$refs.videoDialog.show(url);
    },
  },
  computed: {},
};
</script>
<style lang='scss' scoped>
div,
p {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
.root {
  width: 100vw;
  height: 100vh;
  background: url('./image/bg.png') no-repeat;
  background-size: 100% 100%;
}
.flex_align {
  display: flex;
  align-items: center;
}
.flex_between {
  display: flex;
  justify-content: space-between;
}
.flex_between_align_center {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.flex_center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.flex_col {
  flex-direction: column;
}
.relative {
  position: relative;
}

.swiper_con {
  width: 1200px;
  height: 850px;
}
.title_box{
  padding-top: 100px;
}
.con_box {
  margin-top: 150px;
  width: 1200px;
}
// swiper-container 样式
.swiper-container {
  width: 100% !important;
}
// swiper-slide样式
.swiperMarginRight .swiper-slide {
  margin-right: 10px;
}

// swiper箭头样式开始
.swiper-button-next:after,
.swiper-container-rtl .swiper-button-prev:after {
  font-family: iconfont !important;
  font-size: 26px!important;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e6e7";
  color: #3a3a3a;
}
// 左箭头
.swiper-button-prev[data-v-2dc247a6]:after,
.swiper-container-rtl .swiper-button-next[data-v-2dc247a6]:after {
  font-size: 26px;
  transform: rotate(180deg);

}
.swiper-button-prev:after,
.swiper-container-rtl .swiper-button-next:after {
  font-family: iconfont !important;
  font-size: 26px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e6e7";
  color: #3a3a3a;
}
.swiper-button-prev:after,
.swiper-container-rtl .swiper-button-next:after {
  color: #3a3a3a;
  font-size: 26px;
}
.swiper-button-prev,
.swiper-container-rtl .swiper-button-next {
  left: -146px;
  transform: rotate(180deg);

}
.swiper-button-next,
.swiper-container-rtl .swiper-button-prev {
  right: -146px;

}
.swiper-button-prev,
.swiper-button-next {
  width: 100px;
  height: 100px;
  transition: 0.3s;
  top: 40%;
  // right: -128px;
  transition: 0.3s;
  border: 1px solid #e4e4e0;
  border-radius: 2px;
    background: #fff;

}
.swiper-button-prev:hover,
.swiper-button-next:hover {
  border: 2px solid #e4e4e0;
}
// 禁用样式
.swiper-button-disabled.swiper-button-next:after {
  color: #666 !important;
  background: #f2f2f2;
}
.swiper-button-disabled.swiper-button-prev:after {
  color: #666 !important;
  background: #f2f2f2;
}
.swiper-button-disabled{
  background: #f2f2f2;
}
// swiper箭头样式结束

// dialog中按钮样式
.dialog_btn,
.dialog_btn:hover {
  background: linear-gradient(270deg, #f3bc70 0%, #f8e2c4 100%);
  border: none;
  color: #935d14;
}
/deep/ .el-rate__icon {
  font-size: 22px;
}
</style>
