<template>
  <div class="root flex_center">
    <div class="swiper_con">
      <div class="title_box flex_between">
        <Title :titleName="titleName"></Title>
        <div class="right flex_align" @click="toPath('haishi')">
          优选海师 <i class="el-icon-caret-right listIcon"></i>
        </div>
      </div>
      <!-- 左侧目录 -->
      <div class="menu_left">
        <swiper :options="menuSwiperOptions" v-if="menuSwiperShow">
          <swiper-slide :key="index" v-for="(item, index) in menuList" :class="{ active: menuActiveIndex === index }">{{
            item.title
          }}</swiper-slide>
        </swiper>
      </div>
      <div class="menu_box flex_between">
        <!-- 右侧内容 -->
        <div class="tab_con">
          <div v-show="showFlag">
            <div :key="index" v-for="(item, index) in teacherList"></div>
          </div>
          <swiper ref="teacherSwiper" :options="teacherSwiperOptions">
            <swiper-slide :key="index" v-for="(item, index) in teacherList">
              <div
                class="item relative"
                @click="toPathDetail('haishi/haishiDetails', item.id)"
              >
                <div class="block avatar">
                  <el-avatar :size="100" :src="item.hstp"></el-avatar>
                </div>
                <p class="name">{{ item.name }}</p>
                <p class="school">{{ item.school.zwmc }}</p>
                <p class="education flex_center">
                  <span>{{ item.school.education }}</span>
                  <span class="vertical_line"></span>
                  <span class="text_overflow major"> {{ item.major }}</span>
                </p>
                <div class="block logo flex_center">
                  <div class="line"></div>
                  <el-avatar :size="24" :src="item.school.yxlogo"></el-avatar>
                  <div class="line"></div>
                </div>
                <p class="direction">擅长方向</p>
                <div class="tag flex_center">
                  <div class="tag_item">
                    {{ item.hd_wsjl ? item.hd_wsjl : "暂无" }}
                  </div>
                  <!-- <div class="tag_item">{{ item.hd_wsjl?item.hd_wsjl.split('，')[1]:'没有'}}</div> -->
                </div>
                <!-- <div class="shadow"></div> -->
              </div>
            </swiper-slide>
          </swiper>
          <div class="introduce swiper-button-next"></div>
          <div class="introduce swiper-button-prev"></div>
        </div>
        <!-- <div class="bg"></div> -->
      </div>
      <!-- 箭头 -->
      <Arrow></Arrow>
    </div>
  </div>
</template>
<script>
import commonMeth from "../../commonMeth/index";
import Title from "../title";
import Arrow from "../arrow";
import { getIntroduce } from "@/api/home.js";
let vm = null;
export default {
  name: "",
  components: {
    Title,
    Arrow,
  },
  props: [""],
  data() {
    return {
      titleName: {
        chinese: "海师资质介绍",
        english: "海外名師紹介",
      },
      menuActiveIndex: 0,
      menuList: [],
      videoUrl: require("./image/swiperOneBg.png"),
      teacherSwiperOptions: {
        slidesPerView: 4,
        spaceBetween: 30,
        slidesPerGroup: 4,
        navigation: {
          nextEl: ".introduce.swiper-button-next",
          prevEl: ".introduce.swiper-button-prev",
        },
      },
      menuSwiperOptions: {
        slidesPerView: 5,
        spaceBetween: 0,
        // loop: true,
        direction: "horizontal",
        // centeredSlides: true,
        // slideToClickedSlide: true,
        on: {
          slideChange: function (swiper, event) {
            console.log("当前", this.activeIndex);
            console.log("realIndex", this.realIndex);
            vm.teacherSwiper.slideTo(0, 1000, false);
            vm.teacherList = vm.menuList[this.realIndex].list;
          },
          click: function (e, a) {
            vm.menuActiveIndex = this.clickedIndex;
            vm.teacherSwiper.slideTo(0, 1000, false);
            vm.teacherList = vm.menuList[this.clickedIndex].list;
          },
        },
      },
      teacherList: [
        {
          id: 1,
          name: "Kathleen1",
          school: "普林斯顿大学",
          education: "博士",
          major: "计算机科学",
          logo: "",
          directionOne: "建筑",
          directionTwo: "艺术",
        },
      ],
      showFlag: true,
      menuSwiperShow: false,
    };
  },
  watch: {},
  created() {
    vm = this;
  },
  beforeMount() {},
  mounted() {
    // this.menuSwiper.slideTo(2, 1000, false);
    // 获取数据
    getIntroduce().then((res) => {
      if (res.code === 200) {
        this.menuList = res.data;
        this.teacherList = res.data[0].list;
        this.menuSwiperShow = true;
      }
    });
  },
  methods: {
    // 跳转
    toPath(val) {
      // 公共方法
      commonMeth.openNewTab({ name: val });
    },
    toPathDetail(val, id) {
      this.openNewTabDetail({ name: val, query: { id } });
    },
    openNewTabDetail(to) {
      const routeData = this.$router.resolve(to);
      window.open(`#/${to.name}?id=${to.query.id}`, "_blank");
    },
  },
  computed: {
    // 教师列表swiper
    teacherSwiper() {
      return this.$refs.teacherSwiper.swiper;
    },
    // 目录列表swiper
    menuSwiper() {
      return this.$refs.menuSwiper.swiper;
    },
  },
};
</script>
<style lang='scss' scoped>
div,
p {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
.root {
  background: url(./image/bg.png) no-repeat;
  background-size: (100% 100%);
  height: 100%;
}
.title_box .right {
  letter-spacing: 1px;
  cursor: pointer;
}
.flex_align {
  display: flex;
  align-items: center;
}
.flex_between {
  display: flex;
  justify-content: space-between;
}
.flex_center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.text_overflow {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
/deep/ :root {
  --swiper-theme-color: #d99d66;
}
.swiper_con {
  width: 1200px;
  height: 850px;
}

.listIcon {
  margin-left: 10px;
}
.menu_box {
  margin-top: 120px;
  align-items: center;
}
// 左侧目录
.menu_left {
  // background: #cda5e3;
  margin-top: 100px;
  position: relative;
  .swiper-container {
    width: 800px;
    margin: auto;
    height: 100%;
  }
}
// 原来样式开始
.menu_left /deep/ .swiper-slide {
  // max-height: 36px;
  cursor: pointer;
  border-bottom: 2px solid #e5e5e5;
  color: #5b7084;
  &.active {
        color: #60452f;
        position: relative;
        font-weight: 600;
      &:before {
        content: "";
        display: block;
        width: 72px;
        height: 4px;
        background: #60452f;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: -1px;
        z-index: 1;
        margin-right: 10px;
      }
    }
}
.tab_con /deep/ .swiper-container {
  padding-top: 80px;
}
// .menu_left /deep/ .swiper-slide-active {
//   color: #60452f;
//   position: relative;
//   font-weight: 600;
// }
.menu_left /deep/ .swiper-slide {
  padding: 20px;
  text-align: center;
  position: relative;
  overflow: visible;
}
// .menu_left /deep/ .swiper-slide-active:before {
//   content: "";
//   display: block;
//   width: 72px;
//   height: 4px;
//   background: #60452f;
//   position: absolute;
//   left: 50%;
//   transform: translateX(-50%);
//   bottom: -1px;
//   z-index: 1;
//   margin-right: 10px;
// }
// 原来样式结束

// 右侧内容
.tab_con {
  width: 1200px;
  position: relative;
  .shadow {
    background: #f2f2f2;
    filter: blur(5px);
    width: 100%;
    height: 275px;
    border-radius: 10px 20px 10px 20px;
    position: absolute;
    top: 0;
    z-index: -999;
  }

  .item {
    width: 250px;
    background: #ffffff;
    border-radius: 8px;
    position: relative;
    padding: 60px 0 18px 0;
    cursor: pointer;
    box-shadow: 7px 7px 14px rgba(226, 226, 226, 0.4),
      -7px -7px 14px rgba(226, 226, 226, 0.4);
    transition: all 0.3s;
    &:hover {
      transform: translateY(-10px);
    }
    .name {
      margin-bottom: 15px;
      font-weight: 600;
      font-size: 15px;
      color: #60452f;
    }
    .school {
      margin-bottom: 11px;
      font-size: 18px;
      font-weight: 400;
      color: #5b7084;
    }
    .education {
      margin-bottom: 18px;
      font-size: 16px;
      color: #5b7084;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      white-space: nowrap;
      .vertical_line {
        display: inline-block;
        width: 1px;
        height: 12px;
        background: #666666;
        margin: 0 6px;
      }
      .major {
        max-width: 150px;
      }
    }
    .logo {
      margin-bottom: 18px;
    }
    .direction {
      margin-bottom: 10px;
      font-size: 14px;
      color: #666;
    }
  }
  .item p,
  .item div {
    text-align: center;
  }
  .avatar {
    position: absolute;
    top: -50px;
    left: calc(50% - 50px);
  }
  .tag {
    padding: 0 10px;
  }
  .tag_item {
    padding: 3px 10px;
    background: #caad5f;
    // border-radius: 2px 8px 2px 8px;
    font-size: 16px;
    color: white;
    overflow: hidden;
    text-overflow: ellipsis;
    // display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    white-space: nowrap;
  }

  // 头像样式
  .el-avatar {
    border: 1px solid #f7f8f9;
    position: relative;
  }
  .line {
    width: 26px;
    height: 1px;
    background: #f4f7fd;
  }
}
.bg {
  width: 100vw;
  height: 270px;
  background: rgba(245, 178, 148, 0.5);
  margin-top: -140px;
  left: 0px;
  bottom: 240px;
  position: absolute;
  opacity: 0.3;
}
// swiper箭头样式开始
// 右箭头
.swiper-button-next:after,
.swiper-container-rtl .swiper-button-prev:after {
  font-family: iconfont !important;
  font-size: 26px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e6e7";
  color: #3a3a3a;
}
// 左箭头
.swiper-button-prev:after,
.swiper-container-rtl .swiper-button-next:after {
  font-family: iconfont !important;
  font-size: 26px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e6e7";
  color: #3a3a3a;
  transform: rotate(180deg);
}
.swiper-button-prev,
.swiper-button-next {
  width: 100px;
  height: 100px;
  top: 50%;
  border: 1px solid #e4e4e0;
  transition: 0.3s;
  border-radius: 4px;
  background: #fff;
}

.swiper-button-prev:hover,
.swiper-button-next:hover {
  border: 2px solid #1d70b8;
}
.swiper-button-prev,
.swiper-container-rtl .swiper-button-next {
  right: 95px;
  left: auto;
}
.swiper-button-disabled {
  background: #f2f2f2;
  opacity: 1;
  border: none;
}

//左箭头
.swiper-button-prev {
  left: -145px;
}
//右箭头
.swiper-button-next {
  // left: 0px;
  right: -118px;
}
// 禁用样式
.swiper-button-disabled.swiper-button-next:after {
  color: #d6d6d6 !important;
}
.swiper-button-disabled.swiper-button-prev:after {
  color: #d6d6d6 !important;
}
// swiper箭头样式结束

// 头像样式
/deep/ .el-avatar > img {
  width: 100%;
  height: 100%;
}
</style>
