<template>
  <div class="root flex_center">
    <div class="swiper_con relative">
      <div class="title_box flex_between_align_center">
        <Title :titleName="titleName"></Title>
      </div>
      <div class="menu_box flex_center">
        <div
          class="flex_between_align_center relative"
          v-if="activeName === '1'"
        >
          <div
            class="chart"
            id="major_chart"
            ref="chart"
            v-if="chartShow"
          ></div>
          <div class="hide_chart_logo"></div>
        </div>
      </div>
      <Arrow></Arrow>
    </div>
  </div>
</template>
<script>
import Title from "../title";
import Arrow from "../arrow";
import * as echarts from "echarts";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { getMajor } from "@/api/home.js";
import commonMeth from "../../commonMeth/index";
let vm = null;
export default {
  name: "",
  components: {
    Title,
    Arrow,
  },
  props: ["swiperName"],
  data() {
    return {
      chartShow: true,
      titleName: {
        chinese: "海师专业概览",
        english: "海外名師専攻一覧",
      },
      activeName: "1",
      menuList: [{ label: "硕士" }, { label: "博士" }, { label: "本科" }],
      chartColor: ["#dda377", "#d4c3a1", "#c5a85c", "#b1c69d","#54acd4"],
      allData: [],
      chartData: [
        {
          name: "文科",
          count: 825,
        },
        {
          name: "理工科",
          count: 825,
        },
        {
          name: "商科",
          count: 825,
        },
        {
          name: "医药",
          count: 125,
        },
        {
          name: "艺术",
          count: 625,
        },
      ],
    };
  },
  watch: {
    // 监听父组件swiper变化，重新渲染图表
    swiperName(val) {
      val === "major" ? this.renderChart(true) : this.renderChart(false);
    },
  },
  created() {
    vm = this;
  },
  beforeMount() {},
  mounted() {
    // this.initChart();
    // 获取数据
    getMajor().then((res) => {
      if (res.code === 200) {
        this.allData = res.data;
        this.chartData = res.data;
        this.initChart();
      }
    });
  },
  methods: {
    // 初始化图表
    initChart() {
      am4core.useTheme(am4themes_animated);
      var chart = am4core.create("major_chart", am4charts.XYChart);
      chart.hiddenState.properties.opacity = 0; // this makes initial fade in effect
      //数据
      // 添加数据
      this.chartData.forEach((item, index) => {
        item.color = am4core.color(this.chartColor[index]);
      });
      chart.data = this.chartData;
      //横轴
      var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
      categoryAxis.renderer.grid.template.location = 0;
      categoryAxis.dataFields.category = "name";
      categoryAxis.renderer.minGridDistance = 40;
      // 横向网格不显示
      categoryAxis.renderer.grid.template.strokeOpacity = 0;
      var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
      //纵向网格不显示
      valueAxis.renderer.grid.template.strokeOpacity = 0;
      // y轴不显示
      valueAxis.renderer.labels.template.disabled = true;
      var series = chart.series.push(new am4charts.CurvedColumnSeries());
      series.dataFields.categoryX = "name";
      series.dataFields.valueY = "count";
      series.tooltipText = "{valueY.value}";
      series.columns.template.strokeOpacity = 0;
      series.clustered = false;
      series.hiddenState.properties.visible = true; // this is added in case legend is used and first series is hidden.
      //区域颜色
      series.columns.template.propertyFields.fill = "color";
      // 点击跳转
    //  series.columns.template.events.on("hit", function (event, a) {
    //     let val = "haishi";
    //     vm.openNewTab({
    //       name: val,
    //       query: { major: event.target.dataItem.categories.categoryX },
    //     });
    //   });
      chart.cursor = new am4charts.XYCursor();
      chart.cursor.maxTooltipDistance = 0;
      series.dataItems.template.adapter.add("width", (width, target) => {
        return am4core.percent((target.valueY / valueAxis.max) * 110);
      });
    },

    // 重新渲染图表
    renderChart(val) {
      this.chartShow = val;
      if (val) {
        this.$nextTick(() => {
          this.initChart();
        });
      }
    },

    // 新tab
    openNewTab(to) {
      const routeData = this.$router.resolve(to);
      console.log('日本',to);
      console.log('日本routeData',routeData);
            window.open(`#/${to.name}?major=${to.query.major}`, "_blank");

      // window.open(routeData.href, "_blank");
    },
  },
  computed: {},
};
</script>
<style lang="scss" scoped>
div,
p {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
.root {
  background: url(./image/bg.png) no-repeat;
  background-size: (100% 100%);
  height: 100%;
}
.flex_align {
  display: flex;
  align-items: center;
}
.flex_between {
  display: flex;
  justify-content: space-between;
}
.flex_between_align_center {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.flex_center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.relative {
  position: relative;
}
.swiper_con {
  width: 1200px;
  height: 850px;
}
.menu_box {
  margin-top: 110px;
}

// tooltip
.tooltip_box {
  padding: 10px;
  color: #fff;
  font-size: 16px;
  background: #f3bc70;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.3);
  border: 2px solid #ffffff;
  display: inline-block;
  border-radius: 8px;
}
// 图表容器
.chart {
  width: 1200px;
  height: 500px;
  margin-right: 80px;
}
// 隐藏amcharts logo
.hide_chart_logo {
  width: 70px;
  height: 25px;
  background: #f8f5f3;
  position: absolute;
  bottom: 0;
  left: 0;
}
</style>
