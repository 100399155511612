<template>
  <div class="root flex_center">
    <div class="swiper_con">
      <div class="title_box flex_between">
        <Title :titleName="titleName"></Title>
        <div class="right flex_align animated fadeIn" @click="toPath('haishi')">
          优选海师 <i class="el-icon-caret-right listIcon"></i>
        </div>
      </div>
      <div class="menu_box">
        <!-- tab 目录 -->
        <div class="menu">
          <swiper :options="menuSwiperOptions" ref="conSwiper1">
            <swiper-slide v-for="(item, index) in menuList" :key="index">
              <span
                class="item"
                :class="{ active: menuActiveIndex === index }"
                >{{ item.title }}</span
              >
            </swiper-slide>
          </swiper>
        </div>
        <p class="cur_menu">
          <span class="cur_menu_text">{{ menuNum.text }}</span
          ><span class="cur_menu_num"
            >已成功为{{ menuNum.num }}人实现日本留学愿望</span
          >
        </p>
        <div class="relative">
          <div
            class="casedata_chart"
            id="casedata_chart"
            v-if="chartShow"
          ></div>

          <div class="hide_chart_logo"></div>
        </div>
      </div>
      <!-- 箭头动画 -->
      <Arrow></Arrow>
    </div>
    <!-- 视频播放弹窗 -->
    <VideoDialog ref="videoDialog" type="service"></VideoDialog>
  </div>
</template>
<script>
import Title from "../title";
import Arrow from "../arrow";
import commonMeth from "../../commonMeth/index";
import { getService, getCase } from "@/api/home.js";
import { Bus } from "@/views/home/components/bus.js";
import VideoDialog from "@/views/home/meiyan/components/videoDialog";
import * as echarts from "echarts";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
let vm = null;
export default {
  name: "",
  components: {
    Title,
    Arrow,
    VideoDialog,
  },
  props: ["swiperName"],
  data() {
    return {
      chartShow: true,
      titleName: {
        chinese: "海师成功案例数据展示",
        english: "海外名師成功事例集",
      },
      menuList: [
        //目录列表
        { id: 1, title: "头脑风暴", info: "", type: 0 },
      ],
      allData: [],
      menuActiveIndex: 0, //目录当前index
      menuSwiperOptions: {
        //目录swiper
        slidesPerView: "auto",
        loop: false,
        mousewheel: false,
        on: {
          click: function (e, a) {
            vm.menuActiveIndex = this.clickedIndex;
            vm.chartData = vm.transformKey(
              vm.allData.service[this.clickedIndex].data
            );
            vm.conSwiper1.slideTo(this.clickedIndex, 1000, false);
            vm.initChart();
            vm.menuNum = {
              text: vm.menuList[this.clickedIndex].title,
              num: vm.allData.service[this.clickedIndex].count_sum,
              service_id: vm.allData.service[this.clickedIndex].id,
            };
          },
        },
      },
      videoUrl: "", //视频地址
      fit: "cover",
      chartColor: ["#dea377", "#c3a75c", "#56aed7"],
      chartData: [],
      menuNum: {
        text: "",
        num: "",
        service_id: "",
      },
    };
  },
  watch: {
    // 监听父组件swiper变化，重新渲染图表
    swiperName(val) {
      val === "caseData" ? this.renderChart(true) : this.renderChart(false);
    },
  },
  created() {
    vm = this;
  },
  beforeMount() {},
  mounted() {
    // this.conSwiper.slideTo(0, 1000, false);
    // 获取数据
    getCase().then((res) => {
      if (res.code === 200) {
        let resData = res.data;
        this.allData = resData;
        this.chartData = this.transformKey(this.allData.service[0].data);
        this.initChart();
        this.menuList = resData.service;
        this.menuNum = {
          text: this.menuList[0].title,
          num: this.allData.service[0].count_sum,
          service_id: this.allData.service[0].id,
        };
      }
    });
    this.initChart();
  },

  methods: {
    // 跳转页面
    toPath(val) {
      // 公共方法
      commonMeth.openNewTab({ name: val });
    },
    // 跳转到成功案例swiper
    toCaseData() {
      let menuText = "";
      menuText = this.menuList[this.menuActiveIndex].title;
      Bus.$emit("toCaseData", menuText);
    },

    playVideo(url) {
      this.$refs.videoDialog.show(url);
    },
    initChart() {
      am4core.useTheme(am4themes_animated);
      // Themes end
      let rank = "";
      var chart = am4core.create("casedata_chart", am4charts.SlicedChart);
      chart.hiddenState.properties.opacity = 0; // this makes initial fade in effect
      // 添加数据
      this.chartData.forEach((item, index) => {
        item.color = am4core.color(this.chartColor[index]);
      });
      chart.data = this.chartData;
      var series = chart.series.push(new am4charts.FunnelSeries());
      series.colors.step = 2;
      //修改填充颜色
      series.slices.template.propertyFields.fill = "color";
      //测试修改文字标签
      series.alignLabels = true;
      // series.labelsOpposite = false;

      // 标签显示文字内容
      // series.labels.template.text =
      //   "{value}人成功考入日本排名{category}学院[/]";

      series.dataFields.value = "value";
      series.dataFields.category = "name";
      series.alignLabels = true;
      series.orientation = "horizontal";
      series.bottomRatio = 1;
      // 点击跳转事件
      series.slices.template.events.on("hit", function (event) {
        rank = event.target.dataItem.category;
        let val = "haishi/haishiCase";
        vm.openNewTab({
          name: val,
          query: {
            title: vm.menuNum.text,
            rank,
            service_id: vm.menuNum.service_id,
          },
        });
      });
    },
    // 重新渲染图表
    renderChart(val) {
      this.chartShow = val;
      if (val) {
        this.$nextTick(() => {
          this.initChart();
        });
      }
    },
    // 新tab
    openNewTab(to) {
      const routeData = this.$router.resolve(to);
      window.open(
        `#/${to.name}?title=${to.query.title}&rank=${to.query.rank}&service_id=${to.query.service_id}`,
        "_blank"
      );
    },
    // 转换obj中key值
    transformKey(array) {
      var keyMap = {
        title: "name",
      };
      for (var i = 0; i < array.length; i++) {
        var obj = array[i];
        for (var key in obj) {
          var newKey = keyMap[key];
          if (newKey) {
            obj[newKey] = obj[key];
            delete obj[key];
          }
        }
      }
      return array;
    },
  },
  computed: {
    // 内容swiper
    conSwiper() {
      return this.$refs.conSwiper.swiper;
    },
    // 内容swiper
    conSwiper1() {
      return this.$refs.conSwiper1.swiper;
    },
    // 截取内容字数
    substringStr() {
      return (val, info) => {
        let str = val ? info : info.substring(0, 140);
        return str;
      };
    },
  },
};
</script>
<style lang='scss' scoped>
@import "~@/assets/css/mixin.scss";
// 图表样式s
.casedata_chart {
  @include widthHeight(1200px, 400px);
  margin-top: 28px;
}
// 图表样式e

div,
p {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
.root {
  background: url('./image/bg.png');
  background-size: (100% 100%);
  height: 100%;
}
.title_box {
  position: relative;
}
.title_box .right {
  letter-spacing: 1px;
  cursor: pointer;
}
.flex_box {
  display: flex;
}
.flex_center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.flex_align {
  display: flex;
  align-items: center;
}
.flex_between {
  display: flex;
  justify-content: space-between;
}
.relative {
  position: relative;
}
.swiper_con {
  width: 1200px;
  height: 850px;
  // position: relative;
}

.listIcon {
  margin-left: 10px;
}

.menu_box {
  margin-top: 100px;
  // tab 目录
  .menu {
    padding: 0px 30px;
    position: relative;
    display: flex;
    justify-content: center;
    margin-bottom: 83px;
    .swiper-slide {
      width: auto;
      cursor: pointer;
      box-sizing: border-box;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0px 10px;
    }
    .swiper-container {
      height: 36px;
    }
    .swiper-wrapper {
      height: 100%;
    }
    .item {
      height: 100%;
      white-space: nowrap;
      box-sizing: border-box;
      text-align: center;
      // background: #EDEEF5;
      border-radius: 4px;
      font-size: 18px;
      padding: 6px 10px;
      line-height: 26px;
      &.active {
        color: white;
        background: #60452f;
      }
    }
  }
  // 当前目录文字
  .cur_menu {
    color: #231716;
    padding-left: 50px;
    margin-bottom: 80px;
    .cur_menu_text {
      font-size: 24px;
      margin-right: 19px;
    }
    .cur_menu_text {
      font-size: 20px;
    }
  }
  // tab 内容
  .swiper_box {
    margin-top: 82px;
    left: 0px;
    .swiper-container {
      padding-bottom: 20px;
      width: 100vw;
      position: absolute;
      left: 0px;
      .swiper-slide {
        transform: scale(0.8);
        opacity: 0.5;
      }
      .swiper-slide-active {
        transform: scale(1);
        opacity: 1;
      }
    }
    .tab_con {
      width: 100%;
      display: flex;
      align-items: center;
      background: #f4f4f1;
      border-radius: 8px;
      border: 1px solid #e4e4e0;
      overflow: hidden;
      padding: 40px;
      box-sizing: border-box;
      .text_box {
        // cursor: pointer;
        flex: 1;
        .text_box_600 {
          height: 100%;
          position: relative;
          box-sizing: border-box;
        }
        .title {
          font-size: 26px;
          font-weight: 500;
          color: #3a3a3a;
          cursor: pointer;
        }
        .text {
          margin-top: 30px;
          color: #3a3a3a;
          line-height: 32px;
          height: 300px;
          overflow: auto;
          font-size: 18px;
        }
      }
      .mask {
        width: 100%;
        height: 400px;
        background: rgba(0, 0, 0, 0.3);
        position: absolute;
        top: 0px;
        .btn {
          width: 82px;
          height: 82px;
          border-radius: 50%;
          border: 3px solid rgba(255, 255, 255, 0.3);
          cursor: pointer;
          position: relative;
          right: -250px;
          i {
            font-size: 30px;
            color: rgba(255, 255, 255, 0.5);
            margin-left: 6px;
          }
        }
      }
      .shadow.video {
        background: linear-gradient(180deg, #f8e2c4 0%, #f3bc70 100%);
        .video_box {
          width: 400px;
          height: 400px;
          position: absolute;
          overflow: hidden;
          top: 15px;
        }
      }
      .shadow {
        position: relative;
        width: 300px;
        height: 300px;
        border-radius: 10px 40px 10px 50px;
        flex-shrink: 0;
        margin-left: 60px;
        .video_box {
          width: 100%;
          height: 100%;
          position: absolute;
          overflow: hidden;
        }
      }
    }
  }
}

// tab样式修改

// swiper箭头
// 右箭头
.swiper-button-next:after,
.swiper-container-rtl .swiper-button-prev:after {
  font-family: iconfont !important;
  font-size: 16px;
  content: "\e6c9";
  color: #5b7084;
}
// 左箭头
.swiper-button-prev[data-v-fbba7dfa]:after,
.swiper-container-rtl .swiper-button-next[data-v-fbba7dfa]:after {
  font-size: 16px;
}
.swiper-button-prev:after,
.swiper-container-rtl .swiper-button-next:after {
  font-family: iconfont !important;
  font-size: 16px;
  content: "\e6c8";
  color: #5b7084;
}
.swiper-button-prev:after,
.swiper-container-rtl .swiper-button-next:after {
  color: #333;
}
.swiper-button-prev,
.swiper-button-next {
  top: 0;
  // bottom: 0;
}
.swiper-button-next,
.swiper-container-rtl .swiper-button-prev {
  // left: 95px;
  right: 0px;
}
.swiper-button-prev {
  left: 2px;
}
.swiper-button-prev,
.swiper-button-next {
  width: 20px;
  height: 54px;
  background: #edeef5;
  &:active {
    border: none;
  }
}
// .swiper-button-prev:hover,
// .swiper-button-next:hover {
//   border: 2px solid #000;
// }
.swiper-button-disabled {
  color: red !important;
}
// swiper箭头结束

// dialog中按钮样式
.dialog_btn,
.dialog_btn:hover {
  background: linear-gradient(270deg, #f3bc70 0%, #f8e2c4 100%);
  border: none;
  color: #935d14;
}
// 隐藏amcharts logo
// .hide_chart_logo {
//   width: 70px;
//   height: 25px;
//   background: #fff;
//   position: absolute;
//   bottom: 0;
//   left: 0;
// }
</style>
