<template>
  <div class="root flex_center">
    <div class="swiper_con">
      <div class="title_box flex_between">
        <Title :titleName="titleName"></Title>
        <div class="right flex_align animated fadeIn" @click="toPath('haishi')">
          优选海师 <i class="el-icon-caret-right listIcon"></i>
        </div>
      </div>
      <div class="menu_box">
        <!-- tab 目录 -->
        <div class="menu">
          <swiper :options="menuSwiperOptions" ref="conSwiper1">
            <swiper-slide v-for="(item, index) in menuList" :key="index">
              <span
                class="item"
                :class="{ active: menuActiveIndex === index }"
                >{{ item.title }}</span
              >
            </swiper-slide>
          </swiper>
          <!-- <div
            class="serviceSwiperswiper swiper-button-prev"
            slot="button-prev"
          ></div>
          <div
            class="serviceSwiperswiper swiper-button-next"
            slot="button-next"
          ></div> -->
        </div>
        <!-- tab 内容 -->
        <div class="swiper_box">
          <swiper ref="conSwiper" :options="conSwiperOptions" class="swiper">
            <swiper-slide
              class="tab_con"
              v-for="(item, index) in menuList"
              :key="index"
            >
              <div class="text_box">
                <div class="text_box_600">
                  <p class="title" @click="toCaseData()">{{ item.title }}</p>
                  <p class="japan_title">{{ item.subhead }}</p>
                  <p class="sub_title">{{ item.theme }}</p>
                  <p class="text">
                    {{ item.info }}
                  </p>
                </div>
              </div>
              <div class="shadow">
                <div class="video_box">
                  <video
                    :poster="item.url"
                    v-if="item.type !== 0"
                    :src="item.url_video"
                    style="width: 100%; height: 100%; object-fit:fill; "
                  ></video>
                  <el-image
                    v-else
                    style="width: 100%; height: 100%"
                    :src="item.url"
                    fit="fit"
                  ></el-image>
                  <div
                    class="mask flex_center"
                    v-if="item.type !== 0"
                    @click="playVideo(item.url_video, true)"
                  >
                    <div class="btn flex_center">
                      <i class="iconfont xhgvideo-play"></i>
                    </div>
                  </div>
                </div>
              </div>
            </swiper-slide>
          </swiper>
        </div>
      </div>
      <!-- 箭头动画 -->
      <Arrow></Arrow>
    </div>
    <!-- 视频播放弹窗 -->
    <VideoDialog ref="videoDialog" type="service"></VideoDialog>
  </div>
</template>
<script>
import Title from "../title";
import Arrow from "../arrow";
import commonMeth from "../../commonMeth/index";
import { getService } from "@/api/home.js";
import { Bus } from "@/views/home/components/bus.js";
import VideoDialog from "@/views/home/meiyan/components/videoDialog";

let vm = null;
export default {
  name: "",
  components: {
    Title,
    Arrow,
    VideoDialog,
  },
  props: [""],
  data() {
    return {
      titleName: {
        chinese: "海师提供的服务",
        english: "海外名師が提供するサービス",
      },
      menuList: [
        //目录列表
        { id: 1, title: "头脑风暴", info: "", type: 0, isShowMore: false },
      ],
      menuActiveIndex: 0, //目录当前index
      menuSwiperOptions: {
        //目录swiper
        slidesPerView: "auto",
        loop: false,
        mousewheel: false,
        // navigation: {
        //   nextEl: ".serviceSwiperswiper.swiper-button-next",
        //   prevEl: ".serviceSwiperswiper.swiper-button-prev",
        // },
        on: {
          click: function(e, a) {
            console.log("vm", vm);
            vm.menuActiveIndex = this.clickedIndex;
            vm.conSwiper.slideTo(this.clickedIndex, 1000, false);
          },
          slideChange() {
            console.log("slidechange-service中");
          },
        },
      },
      conSwiperOptions: {
        //内容swiper
        slidesPerView: 2,
        // spaceBetween:60,//间距
        centeredSlides: true, //活动块居中
        initialSlide: 2,
        // loop:true,
        navigation: {
          nextEl: ".serviceSwiperswiper.swiper-button-next",
          prevEl: ".serviceSwiperswiper.swiper-button-prev",
        },
        on: {
          slideChange: function(swiper, event) {
            vm.menuActiveIndex = this.activeIndex;
            vm.conSwiper1.slideTo(this.activeIndex, 1000, false);
          },
        },
      },
      videoUrl: "", //视频地址
      fit: "cover",
    };
  },
  watch: {},
  created() {
    vm = this;
  },
  beforeMount() {},
  mounted() {
    // this.conSwiper.slideTo(0, 1000, false);
    // 获取数据
    getService().then((res) => {
      if (res.code === 200) {
        this.menuList = res.data;
        this.menuList.forEach((item) => {
          if (item.info.length > 140) item.isShowMore = false;
          else item.isShowMore = true;
        });
      }
    });
  },

  methods: {
    // 跳转页面
    toPath(val) {
      // 公共方法
      commonMeth.openNewTab({ name: val });
    },
    // 跳转到成功案例swiper
    toCaseData() {
      let menuText = "";
      menuText = this.menuList[this.menuActiveIndex].title;
      Bus.$emit("toCaseData", menuText);
    },
    // 更多按钮
    showMore(outIndex) {
      this.menuList.forEach((item, index) => {
        if (outIndex === index) {
          item.isShowMore = !item.isShowMore;
        }
      });
      this.menuList.push({ title: "测试", info: "测试" });
      this.menuList.splice(this.menuList.length - 1, 1);
    },
    playVideo(url) {
      this.$refs.videoDialog.show(url);
    },
  },
  computed: {
    // 内容swiper
    conSwiper() {
      return this.$refs.conSwiper.swiper;
    },
    // 内容swiper
    conSwiper1() {
      return this.$refs.conSwiper1.swiper;
    },
    // 截取内容字数
    substringStr() {
      return (val, info) => {
        let str = val ? info : info.substring(0, 140);
        return str;
      };
    },
  },
};
</script>
<style lang="scss" scoped>
div,
p {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
.root {
  background-size: (100% 100%);
  height: 100%;
}
.title_box {
  position: relative;
}
.title_box .right {
  letter-spacing: 1px;
  cursor: pointer;
}
.flex_box {
  display: flex;
}
.flex_center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.flex_align {
  display: flex;
  align-items: center;
}
.flex_between {
  display: flex;
  justify-content: space-between;
}

.swiper_con {
  width: 1200px;
  height: 850px;
  // position: relative;
}

.listIcon {
  margin-left: 10px;
}

.menu_box {
  margin-top: 120px;
  // position: relative;
  // tab 目录
  .menu {
    padding: 0px 30px;
    position: relative;
    display: flex;
    justify-content: center;
    .swiper-slide {
      width: auto;
      cursor: pointer;
      box-sizing: border-box;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0px 10px;
    }
    .swiper-container {
      height: 36px;
    }
    .swiper-wrapper {
      height: 100%;
    }
    .item {
      height: 100%;
      white-space: nowrap;
      box-sizing: border-box;
      text-align: center;
      // background: #EDEEF5;
      border-radius: 4px;
      font-size: 18px;
      padding: 6px 10px;
      line-height: 26px;
      &.active {
        color: white;
        background: #60452f;
      }
    }
  }
  // tab 内容
  .swiper_box {
    margin-top: 82px;
    left: 0px;
    .swiper-container {
      padding-bottom: 20px;
      width: 100vw;
      position: absolute;
      left: 0px;
      .swiper-slide {
        transform: scale(0.8);
        opacity: 0.5;
      }
      .swiper-slide-active {
        transform: scale(1);
        opacity: 1;
      }
      // .swiper-slide-prev{
      //     right:-8%;
      // }
      // .swiper-slide-next{
      //     left:-8%;
      // }
    }
    .tab_con {
      width: 100%;
      display: flex;
      align-items: center;
      background: #f4f4f1;
      border-radius: 8px;
      border: 1px solid #e4e4e0;
      overflow: hidden;
      padding: 40px;
      box-sizing: border-box;
      .text_box {
        // cursor: pointer;
        flex: 1;
        .text_box_600 {
          height: 100%;
          position: relative;
          box-sizing: border-box;
        }
        .title {
          font-size: 26px;
          font-weight: 500;
          color: #3a3a3a;
          cursor: pointer;
          margin-bottom: 8px;
        }
        .japan_title {
          margin-bottom: 38px;
        }
        .sub_title {
          margin-bottom: 20px;
        }
        .text {
          // margin-top: 30px;
          color: #3a3a3a;
          line-height: 32px;
          height: 196px;
          overflow: auto;
          font-size: 18px;
        }
      }
      .mask {
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.3);
        position: absolute;
        top: 0px;
        .btn {
          width: 82px;
          height: 82px;
          border-radius: 50%;
          border: 3px solid rgba(255, 255, 255, 0.3);
          cursor: pointer;
          position: relative;
          i {
            font-size: 30px;
            color: rgba(255, 255, 255, 0.5);
            margin-left: 6px;
          }
        }
      }
      .shadow.video {
        background: linear-gradient(180deg, #f8e2c4 0%, #f3bc70 100%);
        .video_box {
          width: 400px;
          height: 400px;
          position: absolute;
          overflow: hidden;
          top: 15px;
        }
      }
      .shadow {
        position: relative;
        width: 300px;
        height: 300px;
        border-radius: 10px 40px 10px 50px;
        flex-shrink: 0;
        margin-left: 60px;
        .video_box {
          width: 100%;
          height: 100%;
          position: absolute;
          overflow: hidden;
        }
      }
    }
  }
}

// tab样式修改

// swiper箭头
// 右箭头
.swiper-button-next:after,
.swiper-container-rtl .swiper-button-prev:after {
  font-family: iconfont !important;
  font-size: 16px;
  content: "\e6c9";
  color: #5b7084;
}
// 左箭头
.swiper-button-prev[data-v-fbba7dfa]:after,
.swiper-container-rtl .swiper-button-next[data-v-fbba7dfa]:after {
  font-size: 16px;
}
.swiper-button-prev:after,
.swiper-container-rtl .swiper-button-next:after {
  font-family: iconfont !important;
  font-size: 16px;
  content: "\e6c8";
  color: #5b7084;
}
.swiper-button-prev:after,
.swiper-container-rtl .swiper-button-next:after {
  color: #333;
}
.swiper-button-prev,
.swiper-button-next {
  top: 0;
  // bottom: 0;
}
.swiper-button-next,
.swiper-container-rtl .swiper-button-prev {
  // left: 95px;
  right: 0px;
}
.swiper-button-prev {
  left: 2px;
}
.swiper-button-prev,
.swiper-button-next {
  width: 20px;
  height: 54px;
  background: #edeef5;
  &:active {
    border: none;
  }
}
// .swiper-button-prev:hover,
// .swiper-button-next:hover {
//   border: 2px solid #000;
// }
.swiper-button-disabled {
  color: red !important;
}
// swiper箭头结束

// dialog中按钮样式
.dialog_btn,
.dialog_btn:hover {
  background: linear-gradient(270deg, #f3bc70 0%, #f8e2c4 100%);
  border: none;
  color: #935d14;
}
</style>
